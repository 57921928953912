
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CompanyDashboardApi } from '@/companyDashboardAPI/CompanyDashboardAPI';
import { OrderingRule } from '@/companyDashboardAPI/OrderingRule';
import countries from '@/plugins/countries';
import { FormDefinition } from '@/plugins/form-rules';
import DialogDivider from '../components/DialogDivider.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { Branch } from '@/companyDashboardAPI/Branch';

interface CountryOption {
  code: string | null;
  name: string;
}

interface BranchOption {
  id: number | null;
  name: string;
}

@Component({
  components: {
    DialogDivider,
    LoadingSpinner
  }
})
export default class OrderingRuleForm extends Vue {
  @Prop({ required: true })
  private readonly ApiClient!: CompanyDashboardApi;

  @Prop({ required: false, default: null })
  private readonly orderingRule!: OrderingRule | null;

  @Prop({ required: false, default: [] })
  private readonly branches!: Branch[];

  public submittingForm = false;
  public errorMessage = '';

  public originalOrderingRuleData!: OrderingRule | null;
  public orderingRuleData: OrderingRule = {
    RuleId: -1,
    BranchId: null,
    Country: null,
    TotalPrice: 0,
    DeliveryPrice: 0,
    LastUpdate: ''
  };
  public translatedCountries: Array<CountryOption> = [];
  public selectedCountry: CountryOption | null = null;

  public branchOptions: Array<BranchOption> = [];
  public selectedBranch: BranchOption | null = null;

  public form: FormDefinition = {
    valid: false,
    fields: {},
    rules: {
      totalPrice: [this.checkTotalPrice()],
      deliveryPrice: [this.checkDeliveryPrice()]
    }
  };

  /**
   * Called on mounted.
   */
  private async mounted() {
    this.initCountries(this.orderingRule?.Country);
    this.initBranches(this.orderingRule?.BranchId);

    const rule = !this.orderingRule ? this.orderingRuleData : this.orderingRule;
    this.orderingRuleData = JSON.parse(JSON.stringify(rule));
    this.originalOrderingRuleData = JSON.parse(JSON.stringify(rule));
  }

  private initCountries(defaultCountryCode: string | null = null): void {
    const emptyOption = {
      code: null,
      name: this.$vuetify.lang.t(`$vuetify.country_empty`)
    };

    this.translatedCountries = countries.map((country): CountryOption => {
      return {
        code: country,
        name: this.$vuetify.lang.t(`$vuetify.country_${country}`)
      };
    });

    this.translatedCountries.unshift(emptyOption);
    this.selectedCountry =
      this.translatedCountries.find(
        (country) => country.code === defaultCountryCode
      ) ?? emptyOption;
  }

  private initBranches(defaultBranchId: number | null = null): void {
    const emptyOption: BranchOption = {
      id: null,
      name: this.$vuetify.lang.t(`$vuetify.branch_empty`)
    };

    this.branchOptions = this.branches.map((branch): BranchOption => {
      return {
        id: branch.branchId,
        name: branch.branchName ?? ''
      };
    });

    this.branchOptions.unshift(emptyOption);
    this.selectedBranch =
      this.branchOptions.find((branch) => branch.id == defaultBranchId) ??
      emptyOption;
  }

  public get isNew(): boolean {
    return !this.orderingRule;
  }

  public replaceCommaWithDotOnInput(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;

    if (target) {
      let value = target.value;
      value = value.replace(',', '.');
      target.value = value;
    }
  }

  /**
   * Save the ordering rule.
   */
  public async save(): Promise<void> {
    this.submittingForm = true;
    this.errorMessage = '';
    let errorMessage = '';
    let orderingRuleData = JSON.parse(JSON.stringify(this.orderingRuleData));
    orderingRuleData.BranchId = this.selectedBranch?.id;
    orderingRuleData.Country = this.selectedCountry?.code;

    // Save ordering rule.
    if (
      JSON.stringify(orderingRuleData) !==
      JSON.stringify(this.originalOrderingRuleData)
    ) {
      if (this.isNew) {
        await this.ApiClient.postOrderingRule(orderingRuleData).catch(
          (error: Error) => {
            errorMessage +=
              this.$vuetify.lang.t(
                `$vuetify.orderingRuleView_errorMessageCantSaveOrderingRule`
              ) + ' ';
            console.log('error in putOrderingRule');
            console.log(error);
          }
        );
      } else {
        await this.ApiClient.patchOrderingRule(orderingRuleData).catch(
          (error: Error) => {
            errorMessage +=
              this.$vuetify.lang.t(
                `$vuetify.orderingRuleView_errorMessageCantSaveOrderingRule`
              ) + ' ';
            console.log('error in putOrderingRule');
            console.log(error);
          }
        );
      }
    }

    this.errorMessage = errorMessage;
    this.submittingForm = false;

    if (errorMessage === '') {
      this.$emit('saved');
    }
  }

  /**
   * Go back to ordering rules view.
   */
  public goBack(): void {
    this.$router.push({
      name: 'OrderingRules'
    });
  }

  /**
   * Check total price.
   */
  private checkTotalPrice() {
    return (price: any) =>
      (!isNaN(parseFloat(price)) && price >= 0 && price < 100000) ||
      this.$vuetify.lang.t(`$vuetify.form_totalPriceIncorrectValue`);
  }

  /**
   * Check delivery price of the ordering rule.
   */
  private checkDeliveryPrice() {
    return (price: any) =>
      (!isNaN(parseFloat(price)) && price >= 0 && price < 100000) ||
      this.$vuetify.lang.t(`$vuetify.form_deliveryPriceIncorrectValue`);
  }

  public get formTitle(): string {
    return this.isNew
      ? this.$vuetify.lang.t(`$vuetify.orderingRuleView_formTitle_new`)
      : this.$vuetify.lang.t(`$vuetify.orderingRuleView_formTitle`);
  }
}
