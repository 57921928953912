const countries = [
    'DEU',
    'AUT',
    'CHE',
    'LUX',
    'FRA',
    'ITA',
    'ESP',
    'GBR',
    'NLD',
    'BEL',
    'DNK',
    'LIE',
    'NOR',
    'GRC',
    'HUN',
    'SWE',
    'FIN',
    'PRT',
    'ROU',
    'ISL',
    'AND',
    'USA',
    'SAU',
    'PYF',
    'ALB',
    'AGO',
    'AUS',
    'BGR',
    'BIH',
    'BOL',
    'BRA',
    'BLR',
    'CUB',
    'COD',
    'CAN',
    'COL',
    'CRI',
    'CYP',
    'CZE',
    'DOM',
    'DZA',
    'KEN',
    'TZA',
    'UGA',
    'EST',
    'EGY',
    'ETH',
    'GHA',
    'HRV',
    'ISR',
    'IND',
    'IRN',
    'IRL',
    'IRQ',
    'JPN',
    'JAM',
    'JOR',
    'KWT',
    'LTU',
    'LVA',
    'MLT',
    'MAR',
    'MYS',
    'MCO',
    'MDA',
    'MEX',
    'MKD',
    'NZL',
    'OMN',
    'POL',
    'ARG',
    'TWN',
    'CAF',
    'COG',
    'CHL',
    'IDN',
    'LBN',
    'KOR',
    'PHL',
    'SMR',
    'TGO',
    'RUS',
    'RWA',
    'SGP',
    'SVK',
    'SVN',
    'SYR',
    'THA',
    'TUN',
    'TUR',
    'UKR',
    'ARE',
    'VAT',
    'VNM',
    'NGA',
    'MNE',
    'VEN',
    'ZAF',
    'MUS',
    'MDG',
    'SYC',
    'SRB',
    'AZE',
    'KAZ',
    'QAT',
    'SLV',
    'GTM',
    'HND',
    'NIC',
    'PAN',
    'ECU',
    'PRY',
    'PER',
    'URY',
    'ARM',
    'GEO',
    'PAK',
    'LKA',
    'MOZ',
    'NAM',
    'ZMB',
    'ZWE',
    'BWA',
    'MWI',
    'YEM',
    'SEN',
    'CIV',
    'TKM',
    'LBY',
    'CHN',
];

export default countries;
